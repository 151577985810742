<template lang="pug">
    .actual-case-page
        .banner.d-flex.justify-center.align-center(:style="{ backgroundImage: `url(${actualCase})` }")
            h1.text-h3.white--text.orange--text.text--darken-3.font-weight-bold EXPERIENCES
        v-container.main.pb-16
            v-row.mt-10
                v-col(
                    v-for="(actualCase, key) in actualCaseList"
                    :key="key"
                    :cols="12"
                    :sm="4"
                    :md="4"
                    :lg="3"
                    data-aos="zoom-in"
                    data-aos-anchor-placement="top-bottom"
                )
                    v-hover(v-slot="{ hover }")
                        v-card.actual(
                            height="250px"
                            width="100%"
                        )
                            v-img.grey.lighten-2(
                                height="100%"
                                width="100%"
                                :aspect-ratio="4/3"
                                :src="actualCase.imgUrl"
                                :lazy-src="actualCase.imgUrl"
                                :class="{'animate__animated animate__pulse animate__infinite': hover}"
                            )
                                v-overlay.text-center(
                                    absolute
                                    :value="hover"
                                    :opacity="0.7"
                                )
                                    span.text-h6.font-weight-bold {{ actualCase.name }}
                                    v-divider.grey.lighten-2.px-16.mb-2
                                    v-divider.grey.lighten-2.px-16(inset)
        v-dialog(
            v-model="dialog"
            transition="dialog-top-transition"
            overlayOpacity="0.95"
            :width="$vuetify.breakpoint.sm ? '90%' : '80%'"
        )
            v-card.actual-case-detail
                v-toolbar(
                    dark
                    src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
                )
                    v-spacer
                    v-btn(
                        icon
                        @click="dialog = false"
                    )
                        v-icon(:small="$vuetify.breakpoint.xs") fa fa-times
                v-row.pa-0.ma-0(dark)
                    v-col.pa-0.grey.darken-4(
                        :cols="12"
                        :md="7"
                    )
                        v-slide-group.grey.darken-3.pa-5(
                            v-if="showData.imgUrlList && showData.imgUrlList.length > 1"
                            v-model="selectImg"
                            activeClass="success"
                            mandatory
                            showArrows
                            centerActive
                            dark
                        )
                            v-slide-item.mx-1(
                                v-for="(img, key) in showData.imgUrlList"
                                :key="key"
                                v-slot="{ active, toggle }"
                            )
                                v-card.pa-1(
                                    height="50px"
                                    width="50px"
                                    @click="toggle"
                                )
                                    v-img(
                                        height="100%"
                                        width="100%"
                                        :src="img"
                                        :lazySrc="img"
                                    )
                        div.img-block(
                            v-if="showData.imgUrlList"
                            :class="{ 'none-list': showData.imgUrlList.length <= 1 }"
                        )
                            .bg(:style="{ backgroundImage: `url(${showData.imgUrlList[selectImg]})` }")
                            .actual-case-img(:style="{ backgroundImage: `url(${showData.imgUrlList[selectImg]})` }")
                    v-col.px-sm-16.pa-md-5.pa-lg-16(
                        :cols="12"
                        :md="5"
                    )
                        v-list(dense)
                            v-list-item(two-line)
                                v-list-item-avatar
                                    v-icon(color="blue lighten-2") fa fa-building
                                v-list-item-content
                                    .text-subtitle-1.font-weight-medium {{ showData.name }}
                                    v-list-item-subtitle.grey--text 案名
                            v-divider(inset)
                            v-list-item(
                                v-if="showData.construction"
                                two-line
                            )
                                v-list-item-avatar
                                    v-icon(color="blue lighten-2") fa fa-building
                                v-list-item-content
                                    .text-subtitle-1.font-weight-medium {{ showData.construction }}
                                    v-list-item-subtitle.grey--text 營造
                            v-divider(inset)
                            v-list-item(two-line)
                                v-list-item-avatar
                                    v-icon(color="blue lighten-2") fa fa-map
                                v-list-item-content
                                    .text-subtitle-1.font-weight-medium {{ showData.address }}
                                    v-list-item-subtitle.grey--text 地點
                            v-divider(inset)
                            v-list-item(two-line)
                                v-list-item-avatar
                                    v-icon(color="blue lighten-2") fa fa-calendar-alt
                                v-list-item-content
                                    .text-subtitle-1.font-weight-medium {{ showData.date }}
                                    v-list-item-subtitle.grey--text 日期
                            v-divider(inset)
                            v-list-item(two-line)
                                v-list-item-avatar
                                    v-icon(color="blue lighten-2") fa fa-clipboard-list
                                v-list-item-content
                                    .text-subtitle-1.font-weight-medium {{ showData.project }}
                                    v-list-item-subtitle.grey--text 工程項目
                            v-divider(inset)
                            v-list-item(
                                v-if="showData.contractAmount"
                                two-line
                            )
                                v-list-item-avatar
                                    v-icon(color="blue lighten-2") fa fa-dollar-sign
                                v-list-item-content
                                    .text-subtitle-1.font-weight-medium {{ showData.contractAmount }}
                                    v-list-item-subtitle.grey--text 承攬金額
</template>

<script>
import actualCase from '@/assets/actualCase.jpg'
// import actualCase1 from '@/assets/actualCase/actualCase-1.jpg';
// import actualCase2 from '@/assets/actualCase/actualCase-2.jpg';
// import actualCase3 from '@/assets/actualCase/actualCase-3.jpg';
// import actualCase4 from '@/assets/actualCase/actualCase-4.jpg';
// import actualCase5 from '@/assets/actualCase/actualCase-5.jpg';
// import actualCase6 from '@/assets/actualCase/actualCase-6.jpg';
// import actualCase7 from '@/assets/actualCase/actualCase-7.jpg';
// import actualCase8 from '@/assets/actualCase/actualCase-8.jpg';
// import actualCase9 from '@/assets/actualCase/actualCase-9.jpg';
// import actualCase10 from '@/assets/actualCase/actualCase-10.jpg';
// import actualCase11 from '@/assets/actualCase/actualCase-11.jpg';
// import actualCase12 from '@/assets/actualCase/actualCase-12.jpg';
// import actualCase13 from '@/assets/actualCase/actualCase-13.jpg';
// import actualCase14 from '@/assets/actualCase/actualCase-14.jpg';
// import actualCase15 from '@/assets/actualCase/actualCase-15.jpg';
// import actualCase16 from '@/assets/actualCase/actualCase-16.jpg';
// import actualCase17 from '@/assets/actualCase/actualCase-17.jpg';
// import actualCase18 from '@/assets/actualCase/actualCase-18.jpg';
// import actualCase19 from '@/assets/actualCase/actualCase-19.jpg';
// import actualCase20 from '@/assets/actualCase/actualCase-20.jpg';
// import actualCase21 from '@/assets/actualCase/actualCase-21.jpg';
// import actualCase22 from '@/assets/actualCase/actualCase-22.jpg';
// import actualCase23 from '@/assets/actualCase/actualCase-23.jpg';
// import actualCase24 from '@/assets/actualCase/actualCase-24.png';

// --- Brisk

import actualCase1 from '@/assets/brisk/actualCase-1.jpg';
import actualCase2 from '@/assets/brisk/actualCase-2.jpg';
import actualCase3 from '@/assets/brisk/actualCase-3.jpg';
import actualCase4 from '@/assets/brisk/actualCase-4.jpg';
import actualCase5 from '@/assets/brisk/actualCase-5.jpg';
import actualCase6 from '@/assets/brisk/actualCase-6.jpg';
import actualCase7 from '@/assets/brisk/actualCase-7.jpg';
import actualCase8 from '@/assets/brisk/actualCase-8.jpg';
import actualCase17 from '@/assets/brisk/actualCase-17.jpg';
import actualCase21 from '@/assets/brisk/actualCase-21.jpg';


export default {
    data() {
        return {
            actualCase,
            show: false,
            dialog: false,
            showData: {},
            selectImg: 0
        };
    },
    computed: {
        actualCaseList: {
            get: () => {
                return [
                    {
                        name: '',
                        construction: '大陸工程',
                        address: '大直',
                        date: '104年2月',
                        project: '玻璃、矽膠',
                        contractAmount: '450萬',
                        imgUrl: actualCase1,
                        imgUrlList: [actualCase1]
                    },
                    {
                        name: '',
                        construction: '',
                        address: '大直',
                        date: '103年3月',
                        project: '單元組裝',
                        contractAmount: '650萬',
                        imgUrl: actualCase2,
                        imgUrlList: [actualCase2]
                    },
                    {
                        name: '',
                        construction: '華固(品興)',
                        address: '內湖',
                        date: '98年10月',
                        project: '單元組裝、矽膠',
                        contractAmount: '1500萬',
                        imgUrl: actualCase3,
                        imgUrlList: [actualCase3]
                    },
                    {
                        name: '',
                        construction: '',
                        address: '內湖',
                        date: '103年8月',
                        project: '矽膠更新',
                        contractAmount: '400萬',
                        imgUrl: actualCase4,
                        imgUrlList: [actualCase4]
                    },
                    {
                        name: '',
                        construction: '互助營造',
                        address: '內湖',
                        date: '94年2月',
                        project: '玻璃矽膠',
                        contractAmount: '',
                        imgUrl: actualCase5,
                        imgUrlList: [actualCase5]
                    },
                    {
                        name: '',
                        construction: '榮工處',
                        address: '南港',
                        date: '103年6月',
                        project: '玻璃、矽膠',
                        contractAmount: '1800萬',
                        imgUrl: actualCase6,
                        imgUrlList: [actualCase6]
                    },
                    {
                        name: '',
                        construction: '勝堡村',
                        address: '南港',
                        date: '98年7月',
                        project: '單元組裝',
                        contractAmount: '890萬',
                        imgUrl: actualCase7,
                        imgUrlList: [actualCase7]
                    },
                    {
                        name: '',
                        construction: '利晉工程',
                        address: '松仁路',
                        date: '103年4月',
                        project: '玻璃、矽膠',
                        contractAmount: '4700萬',
                        imgUrl: actualCase8,
                        imgUrlList: [actualCase8]
                    },
                    {
                        name: '',
                        construction: '遠揚營造',
                        address: '新竹',
                        date: '99年1月',
                        project: '玻璃、矽膠更新',
                        contractAmount: '4700萬',
                        imgUrl: actualCase17,
                        imgUrlList: [actualCase17]
                    },
                    {
                        name: '',
                        construction: '',
                        address: '板橋',
                        date: '104年',
                        project: '鋁窗及電梯帷幕更新',
                        contractAmount: '3300萬',
                        imgUrl: actualCase21,
                        imgUrlList: [actualCase21]
                    },
                ]
            }
        }
    },
    methods: {
        openDetail(key) {
            this.dialog = true;
            this.showData = this.actualCaseList[key];
        },
        getBackgroundImg(img) {
            return {
                background: `url(${img}) center center`,
                height: '100%',
                width: '100%',
                filter: 'blur(8px)'
            };
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mediaQuery.scss';

.actual::v-deep {
    .slider {
        position: absolute;
        top: auto;
        left: 0;
        width: 100%;
        background-color: #ddd;
    }
}

.img-block {
    position: relative;
    height: calc(100% - 90px);
    width: 100%;

    &.none-list {
        height: 100%;
    }

    @include MQ(tablet) {
        height: 350px;

        &.none-list {
            height: 350px;
        }
    }
    @include MQ(mobile) {
        height: 200px;

        &.none-list {
            height: 200px;
        }
    }

    .bg {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        filter: blur(8px);
    }
    .actual-case-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 90%;
        width: 90%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}
</style>